.spacer {
    height: 100px;
    width: 100%;
    background-color: transparent;
  }
  
  .skip-to-main {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
  
  .skip:focus {
    position: static;
    width: auto;
    height: auto;
  }
  