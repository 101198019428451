.navbar-component-container {
  display: flex;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  height: 100px;
  width: 100%;
  border-bottom: 1px black solid;
}

nav {
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  z-index: 200;
}

.navbar-fav-image {
  z-index: 2000;
  display: flex;
  align-items: center;
}

.nav-fav-large {
  margin-left: 60%;
  width: 100px;
}

.nav-fav-medium {
  margin-left: 80%;
  width: 80px;
}

.nav-fav-small {
  margin-left: 100%;
  width: 55px;
}

.navbar-fav-title {
  width: 100px;
  margin-left: 10%;
  text-align: center;
  font-weight: bold;
  z-index: 2000;
  text-decoration: none;
  word-wrap: break-word;
}

.navbar-logo > *,
.btn-style-navbar > * {
  border-bottom: none !important;
}

.navbar-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  max-width: 1280px;
  padding: 0 30px;
}

.menu {
  display: none;
  justify-content: flex-end;
}

.menu-disable {
  display: none;
  margin: 0 !important;
  padding: 0 !important;
}

.menu-items {
  position: relative;
  padding: 8px 16px;
  margin: 5px 16px;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 600;
  border-radius: 90px;
  text-align: center;
  font-size: 17px;
}

.active {
  border-bottom: 2px solid var(--primary100);
  border-radius: 0 !important;
}

/* MOBILE */
.hamburger {
  display: block;
  user-select: none;
  appearance: none;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

.hamburger span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  border-radius: 6px;
  transform-origin: 0 0;
  transition: 0.4s;
}

.btn-style-navbar {
  margin-top: 20px;
}

.active-hamburger span:nth-child(1) {
  transform: translate(0px, 0px) rotate(45deg);
  -webkit-transform: translate(0px, 0px) rotate(45deg);
}

.active-hamburger span:nth-child(2) {
  animation: slide 0.3s 1 linear forwards;
}

.active-hamburger span:nth-child(3) {
  transform: translate(-2px, 10px) rotate(-45deg);
  -webkit-transform: translate(-2px, 6px) rotate(-45deg);
}

@keyframes slide {
  from {
    transform: translateX(0%);
    display: none;
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    display: none;
    opacity: 0;
  }
}

@keyframes slide-down {
  from {
    top: -200px;
    opacity: 0;
    visibility: hidden;
  }
  to {
    top: 0;
    opacity: 1;
    visibility: visible;
  }
}

.active-dropdown {
  position: fixed;
  top: 80px;
  display: block;
  height: 250px;
  opacity: 1;
  z-index: 1000;
  animation: verticalslide 0.3s 1 linear alternate;
}

.inactive-dropdown {
  position: fixed;
  top: 80px;
  display: none;
  height: 0px;
  opacity: 0;
  animation: verticalslide 0.3s 1 linear reverse;
}

@keyframes verticalslide {
  from {
    display: none;
    opacity: 0;
  }
  to {
    display: block;
    opacity: 1;
  }
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-dropdown {
  display: flex;
  z-index: 100;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  opacity: 1;
  width: 100vw;
  padding: 20px 0;
}

@media (min-width: 778px) {
  .mobile-menu-wrapper {
    display: none;
  }

  .hamburger {
    display: none;
  }
  .menu {
    display: flex;
  }

  .menu a {
    display: flex;
    align-items: center;
    margin: auto 10px;
  }

  .active-dropdown {
    height: 0px;
  }

  .nav-dropdown {
    display: none;
  }

  .menu-items {
    justify-content: center;
    align-items: center;
    /* margin-top: 8px !important; */
    font-weight: 600 !important;
  }

  .btn-style-navbar {
    margin-top: 2px;
  }

  .navbar-fav-title {
    width: 130px;
    margin-left: 20%;
  }
}
